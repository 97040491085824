<template>
  <vx-form
    v-slot="{ loading }"
    :resource="resource"
    @submit="handleSubmit"
  >
    <vx-select
      v-model="formValue.type"
      :rules="rules.type"
      :options="types"
      :clearable="false"
      name="type"
      label="Type"
    />

    <vx-select
      v-model="formValue.status"
      :rules="rules.status"
      :options="statuses[formValue.type]"
      :clearable="false"
      name="status"
      label="Status"
    />

    <vx-input
      v-if="isCardType"
      v-model="formValue.player_id"
      :rules="rules.player_id"
      type="number"
      name="player_id"
      label="Player Id"
    />

    <vx-input
      v-model="formValue.value"
      :rules="rules.value"
      name="value"
      label="Value"
    >
      <template #message>
        {{ valueDescription[formValue.type] }}
      </template>
    </vx-input>

    <vx-input
      v-model="formValue.description"
      :rules="rules.description"
      name="description"
      label="Description"
    />

    <vx-date-picker
      v-model="formValue.ends_at"
      :rules="rules.ends_at"
      name="ends_at"
      label="Ends At"
      type="date"
      :clearable="false"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { computed, reactive, watch } from '@vue/composition-api'
import { VxForm, VxInput, VxSelect, VxDatePicker } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { antifraud, credentialStatuses, credentialTypes, passDataToResource } from '@/services/resources'

export default {
  name: 'CredentialsEditor',
  components: {
    VxDatePicker,
    VxForm,
    VxButton,
    VxSelect,
    VxInput
  },
  setup (props, { emit }) {
    const resource = passDataToResource(antifraud.createCredential, {
      requestParams: ({ type }) => ({ urlParams: { type } })
    })
    const valueDescription = {
      [credentialTypes.card]: 'Example: 538294xxxxxx6579',
      [credentialTypes.bin]: 'Example: 560400'
    }
    const valueValidationRule = {
      [credentialTypes.card]: /\d{6}x{6}\d{4}/i,
      [credentialTypes.bin]: /\d{6}/i
    }

    const types = Object.values(credentialTypes)
    const isCardType = computed(() => formValue.type === credentialTypes.card)

    const { unsafe, whitelisted, blacklisted } = credentialStatuses
    const statuses = {
      [credentialTypes.card]: [whitelisted, blacklisted],
      [credentialTypes.bin]: [unsafe, blacklisted]
    }

    const formValue = reactive({
      type: credentialTypes.card,
      status: credentialStatuses.whitelisted,
      player_id: null,
      value: '',
      ends_at: ''
    })

    const rules = computed(() => (
      {
        type: {
          required: true
        },
        status: {
          required: true
        },
        player_id: {
          required_if: ['type', credentialTypes.card],
          numeric: true
        },
        value: {
          required: true,
          regex: valueValidationRule[formValue.type]
        },
        ends_at: {
          required: true
        }
      })
    )

    watch(() => formValue.type, (type) => {
      formValue.status = statuses[type][0]
    })

    const handleSubmit = ([, res]) => {
      if (res) emit('submit', res)
    }

    return {
      resource,
      formValue,
      rules,
      handleSubmit,

      types,
      isCardType,
      statuses,
      valueDescription
    }
  }
}
</script>
