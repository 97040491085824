<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        @click="openModal"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
      :filters="filters"
    />
    <b-modal
      v-model="modalIsOpen"
      title="Create credentials"
      hide-footer
      title-tag="h4"
    >
      <credentials-editor @submit="handleSubmit" />
    </b-modal>
  </div>
</template>

<script>
import {
  VxTable,
  VxCellDate,
  VxCellPlayerAvatar,
  VxCellButtons,
  VxCellBadges,
  VxCellCredentialsEndsAt,
  VxCellCredentialsExtraPoints
} from '@/components/table'
import {
  antifraud,
  credentialStatuses,
  credentialTypes,
  passDataToResource,
  useResource
} from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { ref } from '@vue/composition-api'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import VxButton from '@/components/buttons/VxButton'
import CredentialsEditor from '@/views/antifraud/CredentialsEditor'
import { boolStatusesOptions } from '@/services/utils'

export default {
  name: 'Credentials',
  components: {
    VxButton,
    VxTable,
    VxCellDate,
    VxCellPlayerAvatar,
    VxCellBadges,
    VxCellCredentialsEndsAt,
    VxPageTitleActions,
    CredentialsEditor,
    VxCellCredentialsExtraPoints
  },
  setup () {
    const tableRef = ref(null)
    const resource = passDataToResource(antifraud.getAllCredentials, {
      requestParams: {
        params: { include: 'player,creator' }
      }
    })

    const { can: canCreate } = useResource(antifraud.createCredential)

    const modalIsOpen = ref(false)

    const openModal = () => {
      modalIsOpen.value = true
    }

    const closeModal = () => {
      modalIsOpen.value = false
    }

    const handleSubmit = () => {
      closeModal()
      tableRef.value.refresh()
    }

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'player',
        label: 'Player',
        component: VxCellPlayerAvatar
      },
      {
        key: 'status',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [credentialStatuses.whitelisted]: 'success',
            [credentialStatuses.unsafe]: 'warning',
            [credentialStatuses.blacklisted]: 'danger'
          }
        }
      },
      { key: 'type' },
      {
        key: 'extra_points',
        component: VxCellCredentialsExtraPoints
      },
      { key: 'value' },
      {
        key: 'ends_at',
        component: VxCellCredentialsEndsAt
      },
      {
        key: 'is_active',
        label: 'Active',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: isActiveButton(item)
        })
      },
      {
        key: 'created_at',
        sortable: true,
        component: VxCellDate
      },
      {
        key: 'updated_at',
        sortable: true,
        component: VxCellDate
      }
    ]

    const filters = [
      {
        key: 'player_id',
        type: filterTypes.number
      },
      {
        key: 'status',
        type: filterTypes.select,
        options: Object.values(credentialStatuses)
      },
      {
        key: 'type',
        type: filterTypes.select,
        options: Object.values(credentialTypes)
      },
      {
        key: 'value',
        type: filterTypes.text
      },
      {
        key: 'is_active',
        label: 'Active',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      }
    ]

    const isActiveButton = ({ id, ends_at, is_active }) => {
      const payload = {
        requestParams: {
          urlParams: { id },
          data: { ends_at, is_active: !is_active }
        }
      }
      const updatedCredentialResource = passDataToResource(antifraud.updatedCredential, payload)

      const turnOff = { ...buttons.turnOff, label: 'Yes' }
      const turnOn = { ...buttons.turnOn, label: 'No' }

      return [{
        ...is_active ? turnOff : turnOn,
        resource: updatedCredentialResource
      }]
    }

    return {
      tableRef,
      resource,
      columns,
      filters,

      modalIsOpen,
      canCreate,
      openModal,
      handleSubmit
    }
  }
}
</script>
